import React, { useState, useEffect, useContext } from 'react';
import TaskTabs from './TaskTabs';
import TaskList from './TaskList';
import '../css/TaskPage.css'; // Optional: Custom CSS for this page
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner';
import { AppContext } from '../context/AppContext';
import config  from '../config';

const TaskPage = () => {
  const [currentTab, setCurrentTab] = useState('Special');
  const [taskData, setTaskData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { state } = useContext(AppContext);
  const username = state.userData.user.username;
  console.log(taskData);

  useEffect(() => {
    const fetchTaskData = async () => {
      try {
      
        const apiUrl = `${config.apiUrl}/get-tasks`;
        const response = await axios.post(apiUrl, { username });
        setTaskData(response.data);
        console.log(response);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch task data:', error);
        setLoading(false);
      }
    };

    fetchTaskData();
  }, []);

  const getCurrentTasks = () => {
    if (taskData) {
      return taskData[currentTab];
    }
    return [];
  };

  if (loading) {
    return (
      <div className="loader-container">
        <ThreeDots color="#00BFFF" height={80} width={80} />
      </div>
    );
  }

  if (!taskData) {
    return <div>Failed to load task data. Please try again later.</div>;
  }

  return (
    <div className="task-page-container">
      <TaskTabs onTabChange={setCurrentTab} />
      <div className="task-list-container">
        <TaskList tasks={taskData[currentTab]} currentTab={currentTab} />
      </div>
    </div>
    );
};


export default TaskPage;
