// src/components/ProgressBar.js
import React, {useContext} from 'react';
import lightningIcon from '../assets/lightning-icon.png'; // Replace with your actual icon path
import '../css/ProgressBar.css'; // Custom CSS for the ProgressBar
import { AppContext } from '../context/AppContext';


const ProgressBar = () => {
  const { state } = useContext(AppContext);
  // var current = state.userData?.user?.bullton_bar_limit;
  const progress = (state.userData?.user?.bullton_bar_current / state.userData?.user?.bullton_bar_limit) * 100;

  return (
    <div className="progress-container text-center">
      <div className="progress-text d-flex align-items-center justify-content-center mb-2">
        <img src={lightningIcon} alt="Lightning Icon" className="lightning-icon me-1" />
        <span className="current">{state.userData?.user?.bullton_bar_current}</span>/<span className="total">{state.userData?.user?.bullton_bar_limit}</span>
      </div>
      <div className="progress mx-auto" style={{ height: '10px', width: '80%' }}>
        <div className=" progress-bar-custom" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  );
};

export default ProgressBar;
