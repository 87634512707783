// import React, { useState, useContext } from 'react';
// import '../css/DailyBoosters.css'; // Custom CSS for Daily Boosters
// import BoosterModal from './BoosterModal';
// import { AppContext } from '../context/AppContext';

// const DailyBoosters = () => {
//   const [showModal, setShowModal] = useState(false);
//   const [selectedBooster, setSelectedBooster] = useState(null);
//   const { state } = useContext(AppContext);

//   const booster = state?.userBoosters?.daily_boosters;
//   console.log(booster);

//   if (!booster) {
//     return <div>Loading...</div>; // Display a loading message or spinner
//   }

//   const openModal = (booster) => {
//     setSelectedBooster(booster);
//     setShowModal(true);
//   };

//   const closeModal = () => {
//     setShowModal(false);
//     setSelectedBooster(null);
//   };

//   return (
//     <div className="daily-boosters">
//       <h3>Your daily boosters:</h3>
//       <div className="row">
        
//           <div key={booster.id} className="col-6">
//             <div className="booster-card" onClick={() => openModal(booster)}>
//               <img src={booster.icon} alt={booster.title} className="booster-icon" />
//               <div className="booster-details">
//                 <h4>{booster.title}</h4>
//                 <p>{booster.bar_refill_count_per_day}/{booster.count}</p>
//               </div>
//             </div>
//           </div>

//       </div>
//       <BoosterModal show={showModal} onClose={closeModal} booster={selectedBooster} />
//     </div>
//   );
// };






// // export default DailyBoosters;
// import React, { useState, useContext } from 'react';
// import '../css/DailyBoosters.css'; // Custom CSS for Daily Boosters
// import BoosterModal from './BoosterModal';
// import { AppContext } from '../context/AppContext';

// const DailyBoosters = () => {
//   const [showModal, setShowModal] = useState(false);
//   const [selectedBooster, setSelectedBooster] = useState(null);
//   const [errorMessage, setErrorMessage] = useState(''); // State for error message
//   const { state } = useContext(AppContext);

//   const booster = state?.userBoosters?.daily_boosters;

//   const openModal = (booster) => {
//     setSelectedBooster(booster);

//     // Check for error: if bar_refill_count_per_day >= count, set the error message
//     if (booster.bar_refill_count_per_day <= 0 ) {
//       setErrorMessage('No more refills available for today!');
//     } else {
//       setErrorMessage(''); // Reset if no error
//     }



//     setShowModal(true);
//   };

//   const closeModal = () => {
//     setShowModal(false);
//     setSelectedBooster(null);
//   };

//   return (
//     <div className="daily-boosters">
//       <h3>Your daily boosters:</h3>
//       <div className="row">
//         <div key={booster.id} className="col-6">
//           <div className="booster-card" onClick={() => openModal(booster)}>
//             <img src={booster.icon} alt={booster.title} className="booster-icon" />
//             <div className="booster-details">
//               <h4>{booster.title}</h4>
//               <p>{booster.bar_refill_count_per_day}/{booster.count}</p>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Pass booster and error message dynamically */}
//       <BoosterModal 
//         show={showModal} 
//         onClose={closeModal} 
//         booster={selectedBooster} 
//         errorMessage={errorMessage} // Pass the error message dynamically
//       />
//     </div>
//   );
// };

// export default DailyBoosters;




import React, { useState, useContext, useEffect } from 'react';
import '../css/DailyBoosters.css'; // Custom CSS for Daily Boosters
import BoosterModal from './BoosterModal';
import { AppContext } from '../context/AppContext';
import { ThreeDots } from 'react-loader-spinner'; // Add loading spinner

const DailyBoosters = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedBooster, setSelectedBooster] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const { state } = useContext(AppContext);

  // Fetch boosters from state
  const booster = state?.userBoosters?.daily_boosters;

  // Handle loading state based on availability of booster data
  useEffect(() => {
    if (booster) {
      setLoading(false); // Set loading to false when boosters are available
    }
  }, [booster]);

  const openModal = (booster) => {
    setSelectedBooster(booster);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedBooster(null);
  };

  // Check if loading or if booster data is missing
  if (loading) {
    return (
      <div className="loader-container">
        <ThreeDots color="#00BFFF" height={80} width={80} />
      </div>
    );
  }

  // If there's no booster data available after loading, show a fallback message
  if (!booster) {
    return <div>No daily boosters available.</div>;
  }

  return (
    <div className="daily-boosters">
      <h3>Your daily boosters:</h3>
      <div className="row">
        {/* Ensure booster is an object and properties exist */}
        <div key={booster?.id || 'default-id'} className="col-6">
          <div className="booster-card" onClick={() => openModal(booster)}>
            <img
              src={booster?.icon || 'default-icon.png'}
              alt={booster?.title || 'Booster'}
              className="booster-icon"
            />
            <div className="booster-details">
              <h4>{booster?.title || 'Untitled Booster'}</h4>
              <p>{booster?.bar_refill_count_per_day || 0}/{booster?.count || 'N/A'}</p>
            </div>
          </div>
        </div>
      </div>
      <BoosterModal show={showModal} onClose={closeModal} booster={selectedBooster} 
       errorMessage={booster?.bar_refill_count_per_day <= 0 ? 'No more refills available for today!' : ''}
      />
    </div>
  );
};

export default DailyBoosters;
