// src/components/ReferralList.js
import React from 'react';
import '../css/ReferralList.css'; // Custom CSS for ReferralList

const ReferralList = ({ referrals }) => {
  return (
    <div className="referral-list">
      <h2>My Referrals:</h2>
      {referrals.length > 0 ? (
        <div className="referrals-container">
          <ul>
            {referrals.map((referral, index) => (
              <li key={index}>{referral}</li>
            ))}
          </ul>
        </div>
      ) : (
        <p className="no-referrals">You don't have referrals 😢</p>
      )}
    </div>
  );
};

export default ReferralList;
