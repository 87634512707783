// src/components/StatsItem.js
import React from 'react';
import '../css/StatsItem.css'; // Custom CSS for StatsItem

const StatsItem = ({ label, value }) => {
  return (
    <div className="stats-item text-center">
      <p className="stats-label">{label}</p>
      <p className="stats-value">{value}</p>
    </div>
  );
};

export default StatsItem;
