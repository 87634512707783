
import React, { useContext, useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import App from './App';
import { ThreeDots } from 'react-loader-spinner';
import { AppContext } from './context/AppContext';
import config from './config';

const LOADING_TIMEOUT = 30000; // 30 seconds
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

const Auth = () => {
  const { dispatch } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [referralCode, setReferralCode] = useState(null);
  const [username, setUsername] = useState(null);
  const [retryCount, setRetryCount] = useState(0);

  // Loading timeout handler
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (loading) {
        setError('Connection timeout. Please try again.');
        setLoading(false);
      }
    }, LOADING_TIMEOUT);

    return () => clearTimeout(timeoutId);
  }, [loading]);

  // Initialize Telegram WebApp
  const initializeTelegram = useCallback(async () => {
    try {
      if (typeof window === 'undefined' || !window.Telegram?.WebApp) {
        throw new Error('Telegram WebApp is not available');
      }

      const tg = window.Telegram.WebApp;

      tg.expand();  // Expand the app to full screen
      tg.ready();   // Mark the app as ready

      // Enable closing confirmation
      tg.enableClosingConfirmation();

      // Disable vertical swipe to close
      tg.disableVerticalSwipes();

      // Set rgb for header
      tg.setHeaderColor('#533300');

      // Set username to either 'username' or 'first_name' if username is not available
      const tgUsername = tg.initDataUnsafe?.user?.username || tg.initDataUnsafe?.user?.first_name;
      if (!tgUsername) {
        throw new Error('Telegram username or first name is required');
      }

      setUsername(tgUsername);

      const startParam = tg.initDataUnsafe.start_param;
      if (startParam) {
        setReferralCode(startParam);
      }

      return true;
    } catch (error) {
      setError(error.message);
      setLoading(false);
      return false;
    }
  }, []);

  // Fetch user data with retry mechanism
  const fetchUserData = useCallback(async () => {
    if (!username) return;

    try {
      const response = await axios.post(
        `${config.apiUrl}/auth`,
        { username, referralCode },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          timeout: 10000,
        }
      );

      if (!response.data?.user) {
        throw new Error('Invalid server response');
      }

      const userData = response.data.user;

      // Validate required fields
      const requiredFields = [
        'current_timestamp',
        'last_activity_timestamp',
        'bullton_bar_speed',
        'bullton_bar_limit',
        'bullton_bar_current',
      ];

      const missingFields = requiredFields.filter((field) => !(field in userData));
      if (missingFields.length > 0) {
        throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
      }

      // Calculate bar refill
      const elapsedTime = userData.current_timestamp - userData.last_activity_timestamp;
      const refillSpeed = parseInt(userData.bullton_bar_speed, 10);
      const refilledAmount = Math.floor(elapsedTime / refillSpeed);
      const updatedBarCurrent = Math.min(
        userData.bullton_bar_current + refilledAmount,
        userData.bullton_bar_limit
      );

      // Update context
      dispatch({
        type: 'SET_USER_DATA',
        payload: {
          ...response.data,
          user: { ...userData, bullton_bar_current: updatedBarCurrent },
        },
      });
      dispatch({ type: 'SET_AUTHENTICATED', payload: true });
      setLoading(false);
    } catch (error) {
      console.error('API Error:', error);

      if (retryCount < MAX_RETRIES) {
        setRetryCount((prev) => prev + 1);
        setTimeout(() => fetchUserData(), RETRY_DELAY * (retryCount + 1));
      } else {
        setError('Failed to load user data. Please try again later.');
        dispatch({ type: 'SET_AUTHENTICATED', payload: false });
        setLoading(false);
      }
    }
  }, [username, referralCode, dispatch, retryCount]);

  // Initialize Telegram
  useEffect(() => {
    initializeTelegram();
  }, [initializeTelegram]);

  // Fetch data when username is available
  useEffect(() => {
    if (username) {
      fetchUserData();
    }
  }, [username, fetchUserData]);

  // Error display
  if (error) {
    return (
      <div className="error-container">
        <div className="error-message">
          <h3>Error</h3>
          <p>{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="retry-button"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  // Loading state
  if (loading) {
    return (
      <div className="loader-container">
        <ThreeDots
          color="#00BFFF"
          height={80}
          width={80}
          timeout={LOADING_TIMEOUT}
        />
      </div>
    );
  }

  return <App />;
};

export default Auth;


// import React, { useContext, useEffect, useState, useCallback } from 'react';
// import axios from 'axios';
// import App from './App';
// import { ThreeDots } from 'react-loader-spinner';
// import { AppContext } from './context/AppContext';
// import config from './config';

// const LOADING_TIMEOUT = 30000; // 30 seconds
// const MAX_RETRIES = 3;
// const RETRY_DELAY = 1000;

// const Auth = () => {
//   const { dispatch } = useContext(AppContext);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [referralCode, setReferralCode] = useState(null);
//   const [username, setUsername] = useState('staticUsername'); // Static username
//   const [retryCount, setRetryCount] = useState(0);

//   // Loading timeout handler
//   useEffect(() => {
//     const timeoutId = setTimeout(() => {
//       if (loading) {
//         setError('Connection timeout. Please try again.');
//         setLoading(false);
//       }
//     }, LOADING_TIMEOUT);

//     return () => clearTimeout(timeoutId);
//   }, [loading]);

//   // Fetch user data with retry mechanism
//   const fetchUserData = useCallback(async () => {
//     if (!username) return;

//     try {
//       const response = await axios.post(
//         `${config.apiUrl}/auth`,
//         { username, referralCode },
//         {
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           timeout: 10000,
//         }
//       );

//       if (!response.data?.user) {
//         throw new Error('Invalid server response');
//       }

//       const userData = response.data.user;

//       // Validate required fields
//       const requiredFields = [
//         'current_timestamp',
//         'last_activity_timestamp',
//         'bullton_bar_speed',
//         'bullton_bar_limit',
//         'bullton_bar_current',
//       ];

//       const missingFields = requiredFields.filter((field) => !(field in userData));
//       if (missingFields.length > 0) {
//         throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
//       }

//       // Calculate bar refill
//       const elapsedTime = userData.current_timestamp - userData.last_activity_timestamp;
//       const refillSpeed = parseInt(userData.bullton_bar_speed, 10);
//       const refilledAmount = Math.floor(elapsedTime / refillSpeed);
//       const updatedBarCurrent = Math.min(
//         userData.bullton_bar_current + refilledAmount,
//         userData.bullton_bar_limit
//       );

//       // Update context
//       dispatch({
//         type: 'SET_USER_DATA',
//         payload: {
//           ...response.data,
//           user: { ...userData, bullton_bar_current: updatedBarCurrent },
//         },
//       });
//       dispatch({ type: 'SET_AUTHENTICATED', payload: true });
//       setLoading(false);
//     } catch (error) {
//       console.error('API Error:', error);

//       if (retryCount < MAX_RETRIES) {
//         setRetryCount((prev) => prev + 1);
//         setTimeout(() => fetchUserData(), RETRY_DELAY * (retryCount + 1));
//       } else {
//         setError('Failed to load user data. Please try again later.');
//         dispatch({ type: 'SET_AUTHENTICATED', payload: false });
//         setLoading(false);
//       }
//     }
//   }, [username, referralCode, dispatch, retryCount]);

//   // Fetch data when username is available
//   useEffect(() => {
//     if (username) {
//       fetchUserData();
//     }
//   }, [username, fetchUserData]);

//   // Error display
//   if (error) {
//     return (
//       <div className="error-container">
//         <div className="error-message">
//           <h3>Error</h3>
//           <p>{error}</p>
//           <button
//             onClick={() => window.location.reload()}
//             className="retry-button"
//           >
//             Retry
//           </button>
//         </div>
//       </div>
//     );
//   }

//   // Loading state
//   if (loading) {
//     return (
//       <div className="loader-container">
//         <ThreeDots
//           color="#00BFFF"
//           height={80}
//           width={80}
//           timeout={LOADING_TIMEOUT}
//         />
//       </div>
//     );
//   }

//   return <App />;
// };

// export default Auth;

