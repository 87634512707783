// // src/components/BoosterModal.js
// import React, { useState, useContext } from 'react';
// import PropTypes from 'prop-types';
// import '../css/BoosterModal.css'; // Custom CSS for Booster Modal
// import coin from '../assets/coin-icon.png';
// import axios from 'axios';
// import config from '../config';
// import { AppContext } from '../context/AppContext';
// import { ThreeDots } from 'react-loader-spinner';

// const BoosterModal = ({ show, onClose, booster }) => {
//   const [loading, setLoading] = useState(false);
//   const { state, dispatch } = useContext(AppContext);

//   if (!show) return null;

//   const getBoost = async (title) => {
//     try {
//       const apiUrl = `${config.apiUrl}/apply-boost`;
//       const username = state.userData?.user?.username;
//       setLoading(true);
//       const response = await axios.post(apiUrl, { username, title });
//       if (response.data.type === 'Full Tank') {
//         dispatch({ type: 'SET_FULL_TANK', payload: response.data.boosters });
//       } 
//       setLoading(false);
//       onClose();
//       // window.location.reload();
//     } catch (error) {
//       console.error('Failed to get boost:', error);
//       alert('Failed to get boost. Please try again.');
//       setLoading(false);
//     }
//   };

//   const getOtherBoosters = async (booster) => {
//     try {
//       const apiUrl = `${config.apiUrl}/apply-other-boost`;
//       const username = state.userData?.user?.username;
//       setLoading(true);
//       const response = await axios.post(apiUrl, { username, booster });
//       dispatch({ type: 'SET_USER_BOOSTERS', payload: response.data.boosters });
//       setLoading(false);
//       onClose();
//       // window.location.reload();
//     } catch (error) {
//       console.error('Failed to get other boosters:', error);
//       alert('Failed to get other boosters. Please try again.');
//       setLoading(false);
//     }
//   };

//   if (loading) {
//     return (
//       <div className="loader-container">
//         <ThreeDots color="#00BFFF" height={80} width={80} />
//       </div>
//     );
//   }

//   return (
//     <div className="modal-overlay" onClick={onClose}>
//       <div className="modal-content-custom" onClick={(e) => e.stopPropagation()}>
//         <button className="close-button" onClick={onClose}>&times;</button>
//         {booster && (
//           <>
//             <img src={booster.icon} alt={booster.title} className="modal-icon" />
//             <h2>{booster.title}</h2>
//             <p>{booster.text}</p>
//             <div className="modal-cost">
//               <img src={coin} alt="Coin Icon" />
//               <span>{booster.cost}</span>
//               <span>{booster.level}</span>
//             </div>
//             {booster.bar_refill_count_per_day > 0 ? (
//               <button className="get-button" onClick={() => getBoost(booster.title)}>Get it!</button>
//             ) : (
//               booster.title === "Full Tank" && <p>No refill available for today.</p>
//             )}
//             <button className="get-button" onClick={() => getOtherBoosters(booster)}>Get it!</button>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// BoosterModal.propTypes = {
//   show: PropTypes.bool.isRequired,
//   onClose: PropTypes.func.isRequired,
//   booster: PropTypes.object
// };
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import '../css/BoosterModal.css'; // Custom CSS for Booster Modal
import coin from '../assets/coin-icon.png';
import axios from 'axios';
import config from '../config';
import { AppContext } from '../context/AppContext';
import { ThreeDots } from 'react-loader-spinner';

const BoosterModal = ({ show, onClose, booster, errorMessage }) => {
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useContext(AppContext);

  if (!show) return null;

  const getBoost = async (title) => {
    try {
      const apiUrl = `${config.apiUrl}/apply-boost`;
      const username = state.userData?.user?.username;
      setLoading(true);
      const response = await axios.post(apiUrl, { username, title });
      if (response.data.type === 'Full Tank') {
        dispatch({ type: 'SET_FULL_TANK', payload: response.data.boosters });
      }
      setLoading(false);
      onClose();
    } catch (error) {
      console.error('Failed to get boost:', error);
      alert('Failed to get boost. Please try again.');
      setLoading(false);
    }
  };

  const getOtherBoosters = async (booster) => {
    try {
      const apiUrl = `${config.apiUrl}/apply-other-boost`;
      const username = state.userData?.user?.username;
      setLoading(true);
  
      const response = await axios.post(apiUrl, { username, booster });
      
      // Check if the response is successful
      if (response.data.status === "success") {
        // Optionally, dispatch an action if needed
        dispatch({ type: 'SET_USER_BOOSTERS', payload: response.data.boosters });
  
        // Optionally, you can show the success message
        console.log('Booster applied successfully:', response.data.message);
  
        // You might not need to reload the window, it's better to rely on state updates
        setLoading(false);
        window.location.reload()
        onClose();
      } else {
        throw new Error('Unexpected API response');
      }
    } catch (error) {
      console.error('Failed to get other boosters:', error);
      alert('Failed to get other boosters. Please try again.');
      setLoading(false);
    }
  };

  
  if (loading) {
    return (
      <div className="loader-container">
        <ThreeDots color="#00BFFF" height={80} width={80} />
      </div>
    );
  }

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content-custom" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>&times;</button>
        {booster && (
          <>
            {/* Safe rendering with optional chaining */}
            <img src={booster?.icon || ''} alt={booster?.title || 'Booster'} className="modal-icon" />
            <h2>{booster?.title || 'Untitled Booster'}</h2>

            {booster.title === "Full Tank" ? (
              <>
                {/* Show error message if passed from the parent component */}
                {errorMessage ? (
                  <p className="error-message">{errorMessage}</p>
                ) : (
                  <>
                    <p>{booster?.text || 'No description available'}</p>
                    <button className="get-button" onClick={() => getBoost(booster?.title)}>Get it!</button>
                  </>
                )}
              </>
            ) : (
              <>
                {/* Check if there's an error message */}
                {errorMessage ? (
                  <p className="error-message">{errorMessage}</p>
                ) : (
                  <>
                    {/* Safe rendering of cost and level */}
                    <div className="modal-cost">
                      <img src={coin} alt="Coin Icon" />
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>{booster?.cost || 'N/A'}</span>
                        <div style={{ width: '1px', height: '20px', backgroundColor: '#ccc', margin: '0 8px' }}></div>
                        <span>{booster?.to_limit || booster?.to_level || booster?.to_speed || 'N/A'}</span>
                      </div>

                    </div>
                    <button className="get-button" onClick={() => getOtherBoosters(booster)}>Get it!</button>
                  </>
                )}
              </>
            )}

          </>
        )}
      </div>
    </div>
  );
};

BoosterModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  booster: PropTypes.object,
  errorMessage: PropTypes.string, // Accept errorMessage from the parent component
};

export default BoosterModal;
