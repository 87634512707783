import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TapPage from './components/TapPage';
import CoinDisplay from './components/CoinDisplay';
import LargeCoin from './components/LargeCoin';
import ProgressBar from './components/ProgressBar';
import IconBar from './components/IconBar';
import TaskPage from './components/TaskPage';
import BoostPage from './components/BoostPage';
import StatsPage from './components/StatsPage';
import RefPage from './components/RefPage';
import BackgroundAnimation from './components/BackgroundParticles';
import { AppContext } from './context/AppContext'; // Import the context

import './App.css';

function App() {
  const { state } = useContext(AppContext);

  return (
    <Router>
      <div className="App text-white py-5">
        <BackgroundAnimation />
        <Routes>
          {/* User routes */}
          <Route path="/" element={<TapPage />} />
          <Route path="/tasks" element={
            <div className="container">
              <CoinDisplay />
              <TaskPage />
              <IconBar />
            </div>
          } />
          <Route path="/boost" element={<BoostPage />} />
          <Route path="/stats" element={<StatsPage />} />
          <Route path="/ref" element={<RefPage />} />

         
        </Routes>
      </div>
    </Router>
  );
}

export default App;
