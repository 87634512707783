// src/components/TaskTabs.js
import React, { useState } from 'react';
import '../css/TaskTabs.css'; // Custom CSS for TaskTabs

const TaskTabs = ({ onTabChange }) => {
  const [activeTab, setActiveTab] = useState('Special');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    onTabChange(tab); // Notify parent about tab change
  };

  return (
    <div className="task-tabs-container">
      <div
        className={`task-tab ${activeTab === 'Special' ? 'active' : ''}`}
        onClick={() => handleTabClick('Special')}
      >
        Special <span className="notification-dot"></span>
      </div>
      <div
        className={`task-tab ${activeTab === 'Leagues' ? 'active' : ''}`}
        onClick={() => handleTabClick('Leagues')}
      >
        Leagues
      </div>
      <div
        className={`task-tab ${activeTab === 'RefTasks' ? 'active' : ''}`}
        onClick={() => handleTabClick('RefTasks')}
      >
        Ref Tasks
      </div>
    </div>
  );
};

export default TaskTabs;
