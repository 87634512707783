import React, { useEffect } from "react";

const BackgroundAnimation = () => {
  useEffect(() => {
    const particleCount = 50; // Number of particles
    const particles = [];

    const createParticle = () => {
      const particle = document.createElement("div");
      particle.classList.add("particle");

      // Random position and size for particles
      const size = Math.random() * 5 + 5; // Particle size between 5px and 10px
      particle.style.width = `${size}px`;
      particle.style.height = `${size}px`;

      const xPosition = Math.random() * window.innerWidth;
      const yPosition = Math.random() * window.innerHeight;
      particle.style.top = `${yPosition}px`;
      particle.style.left = `${xPosition}px`;

      const animationDelay = Math.random() * 5 + "s"; // Random delay for animation
      const duration = Math.random() * 5 + 5 + "s"; // Random duration for animation

      particle.style.animation = `move ${duration} linear infinite, fadeInOut ${duration} ease-in-out infinite`;
      particle.style.animationDelay = animationDelay;

      particles.push(particle);
      document.querySelector(".app-background").appendChild(particle);
    };

    for (let i = 0; i < particleCount; i++) {
      createParticle();
    }

    return () => {
      particles.forEach((particle) => particle.remove());
    };
  }, []);

  return <div className="app-background" />;
};

export default BackgroundAnimation;
