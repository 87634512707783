// src/components/StatsPage.js
import React, {useEffect, useState} from 'react';
import CoinDisplay from './CoinDisplay';
import StatsItem from './StatsItem';
import IconBar from './IconBar';
import '../css/StatsPage.css'; // Custom CSS for StatsPage
import config from '../config';
import { ThreeDots } from 'react-loader-spinner';

const StatsPage = () => {
  const [statsData, setStatsData] = useState([]);
  const [loading, setLoading] = useState(true);




  useEffect(() => {
    // Example code to fetch statistics data
    const apiUrl = `${config.apiUrl}/stats`;
    setLoading(true);
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        console.log('Statistics data:', data);
        setStatsData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error('Failed to fetch statistics data:', error);
      });
  }, []);

  if (loading) {
    return (
      <div className="loader-container">
        <ThreeDots color="#00BFFF" height={80} width={80} />
      </div>
    );
  }

  return (
    <div className="stats-page container">
      <CoinDisplay />
      <div className="stats-list mt-4">
        {statsData.map((stat, index) => (
          <StatsItem key={index} label={stat.label} value={stat.value} />
        ))}
      </div>
      <IconBar />
    </div>
  );
};

export default StatsPage;
