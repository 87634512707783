// src/components/IconBar.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import refIcon from '../assets/ref-icon.png';
import taskIcon from '../assets/task-icon.png';
import tapIcon from '../assets/tap-icon.png';
import boostIcon from '../assets/boost-icon.png';
import statsIcon from '../assets/stats-icon.png';
import '../css/IconBar.css'; // Custom CSS for the IconBar

const IconBar = () => {
  const location = useLocation(); // Get the current location

  // Determine which icon is active based on the current path
  const getActiveClass = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <div className="icon-bar d-flex justify-content-around pb-4">
      <Link to="/ref" className={`icon-card text-center ${getActiveClass('/ref')}`}>
        <img src={refIcon} alt="Ref Icon" className="icon-img" />
        <div className="icon-text">Ref</div>
      </Link>
      <Link to="/tasks" className={`icon-card text-center ${getActiveClass('/tasks')}`}>
        <img src={taskIcon} alt="Task Icon" className="icon-img" />
        <div className="icon-text">Task</div>
      </Link>
      <Link to='/' className={`icon-card text-center ${getActiveClass('/')}`}>
        <img src={tapIcon} alt="Tap Icon" className="icon-img" />
        <div className="icon-text">Tap</div>
      </Link>
      <Link to="/boost" className={`icon-card text-center ${getActiveClass('/boost')}`}>
        <img src={boostIcon} alt="Boost Icon" className="icon-img" />
        <div className="icon-text">Boost</div>
      </Link>
      <Link to="/stats" className={`icon-card text-center ${getActiveClass('/stats')}`}>
        <img src={statsIcon} alt="Stats Icon" className="icon-img" />
        <div className="icon-text">Stats</div>
      </Link>
    </div>
  );
};

export default IconBar;
