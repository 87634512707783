import React, { createContext, useReducer, useEffect } from 'react';
// import config from '../config';
// import axios from 'axios';


// Create context
const AppContext = createContext();

// Define initial state
const initialState = {
  userData: null,
  authenticated: false,
  userBoosters: null,
};

// Define reducer
const appReducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER_DATA':
      return {
        ...state,
        userData: action.payload,
      };
    case 'SET_AUTHENTICATED':
      return {
        ...state,
        authenticated: action.payload,
      };
    case 'UPDATE_BALANCE':
      return {
        ...state,
        userData: {
          ...state.userData,
          user: {
            ...state.userData.user,
            balance: state.userData.user.balance + parseInt(action.payload),
          }
        }
      };
    case 'UPDATE_BULLTON_BAR':
      return {
        ...state,
        userData: {
          ...state.userData,
          user: {
            ...state.userData.user,
            bullton_bar_current: state.userData.user.bullton_bar_current - action.payload,
          }
        }
      };
    case 'Update_Level_Limits':
      const [level, starting_range, maximum_range] = action.payload;
      return {
        ...state,
        userData: {
          ...state.userData,
          user: {
            ...state.userData.user,
            level: level, // Update level
            coin_range: {
              ...state.userData.user.coin_range,
              maximum_range: maximum_range,
              starting_range: starting_range, // Update starting range
            },
          },
        },
      };
    
    case 'SET_USER_BOOSTERS':
      return {
        ...state,
        userBoosters: action.payload,
      };

    case 'SET_FULL_TANK':
      // console.log("in app context and Full tank response is :", action.payload);
      return {
        ...state,
        userData: {
          ...state.userData,
          user: {
            ...state.userData.user,
            bullton_bar_current: action.payload,
          },
        },
        
      };

    case "SET_USER_ENERGY_LIMIT":
      return {
        ...state,
        userData: {
          ...state.userData,
          user: {
            ...state.userData.user,
            bullton_bar_limit: action.payload,
          },
        },
      };

    case 'SET_USER_MULTI_TAP':
      return {
        ...state,
        userData: {
          ...state.userData,
          user: {
            ...state.userData.user,
            bullton_value_per_tap: action.payload,
          },
        },
      };
    default:
      return state;
  }
};

// Create provider component
const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  useEffect(() => {
    if (!state.userData || !state.userData.user) return;


    const bullton_bar_speed = state.userData.user.bullton_bar_speed;
    // const bullton_bar_speed = 1; 

    const incrementBulltonBar = () => {
      if (state.userData.user.bullton_bar_current < state.userData.user.bullton_bar_limit) {
        dispatch({ type: 'UPDATE_BULLTON_BAR', payload: -1 });
      }
    };

    const interval = setInterval(() => {
      incrementBulltonBar();
    }, bullton_bar_speed * 1000); // Convert seconds to milliseconds

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [state.userData, dispatch]);

  // write a function to update the balance in backend with post api after every 3 secs
  // useEffect(() => {
  //   if (!state.userData || !state.userData.user) return;

  //   const interval = setInterval(() => {
  //     const apiUrl = `${config.apiUrl}/update-balance-bar`;
  //     const username = state.userData.user.username;
  //     const balance = state.userData.user.balance;
  //     const bar_current = state.userData.user.bullton_bar_current;
  //     const updateBalance = async () => {
  //       try {
  //         const response = await axios.post(apiUrl, { username, balance, bar_current });
  //         console.log('Balance updated:', response.data);
  //       } catch (error) {
  //         console.error('Failed to update balance:', error);
  //       }
  //     };
  //     updateBalance();
  //   }, 5000);

  //   return () => clearInterval(interval);
  // }, [state.userData]); 

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
