// src/components/CoinDisplay.js
import React, {useContext} from 'react';
import coinIcon from '../assets/coin-icon.png';    // Replace with your actual icon paths
import trophyIcon from '../assets/trophy-icon.png';
import '../css/CoinDisplay.css';                      // Custom CSS for the CoinDisplay
import { AppContext } from '../context/AppContext';

const CoinDisplay = () => {
  const { state } = useContext(AppContext);


  return (
    <div className="coin-display text-center">
      <div className="coin-row d-flex justify-content-center align-items-center mb-3">
        <img src={coinIcon} alt="Coin Icon" className="coin-icon" />
        <span className="coin-text ms-2">{state.userData?.user?.balance || 0}</span>
      </div>
      <div className="trophy-row d-flex justify-content-center align-items-center">
        <img src={trophyIcon} alt="Trophy Icon" className="trophy-icon me-2" />
        <span className="trophy-text">{state.userData?.user?.level || "Unknown"}</span>
        {/* <span className="arrow ms-1">&gt;</span> */}
      </div>
    </div>
  );
};

export default CoinDisplay;
