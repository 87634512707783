import React, { useEffect, useState, useContext } from 'react';
import CoinDisplay from './CoinDisplay';
import ReferralCount from './ReferralCount';
import InviteLink from './InviteLink';
import ReferralList from './ReferralList';
import IconBar from './IconBar';
import '../css/RefPage.css'; // Custom CSS for RefPage
import { ThreeDots } from 'react-loader-spinner';
import axios from 'axios';
import { AppContext } from '../context/AppContext';
import config from '../config';

const RefPage = () => {
  const [referralData, setReferralData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { state } = useContext(AppContext);



  useEffect(() => {
    const username = state.userData?.user?.username; // Replace with the actual username logic
    console.log(state);

    const fetchReferralData = async () => {
      try {
        // const response = await axios.post('http://127.0.0.1:5000/api/get-referrals', { username });
        const response = await axios.post(`${config.apiUrl}/get-referrals`, { username });
        setReferralData(response.data);
        console.log('Referral data:', response.data);
      } catch (error) {
        console.error('Failed to fetch referral data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReferralData();
  }, []);

  if (loading) {
    return (
      <div className="loader-container">
        <ThreeDots color="#00BFFF" height={80} width={80} />
      </div>
    );
  }

  if (!referralData) {
    return <div>Failed to load referral data. Please try again later.</div>;
  }

  return (
    <div className="ref-page container">
      <ReferralCount count={referralData.referralCount}  />
      <InviteLink code={referralData.referralCode} />
      <ReferralList referrals={referralData.referredUsers} />
      <IconBar />
    </div>
  );
};

export default RefPage;
