
// import React, { useState, useContext } from 'react';
// import BoosterModal from './BoosterModal';
// import '../css/BoostersList.css'; // Custom CSS for Boosters List
// import { AppContext } from '../context/AppContext';

// const BoostersList = () => {
//   const [showModal, setShowModal] = useState(false);
//   const [selectedBooster, setSelectedBooster] = useState(null);
//   const { state } = useContext(AppContext);

//   // Get the user's balance from state
//   const userBalance = state?.userData?.user?.balance || 0;
//   const boosters = state?.userBoosters?.other_boosters;

//   if (!boosters || Object.keys(boosters).length === 0) {
//     return <div>No other boosters available.</div>;
//   }

//   const openModal = (booster) => {
//     if (booster.cost) { // Only open modal for boosters with cost
//       setSelectedBooster(booster);
//       setShowModal(true);
//     }
//   };

//   const closeModal = () => {
//     setShowModal(false);
//     setSelectedBooster(null);
//   };

//   return (
//     <div className="boosters-list">
//       <h3>Other Boosters:</h3>
//       <div className="boosters-container">
//         {Object.values(boosters).map((booster) => {
//           // Compare balance with the booster cost
//           const canAfford = booster.cost && userBalance >= booster.cost;

//           return (
//             <div key={booster.title} className="booster-item d-flex align-items-center">
              
//               {/* Only display the icon if the booster has one */}
//               {booster.icon && (
//                 <img src={booster.icon} alt={booster.title} className="booster-item-icon" />
//               )}

//               <div className="booster-item-details flex-grow-1">
//                 <h4>{booster.title}</h4>

//                 {/* If the booster only has text (like bar_limit), show it without cost or arrow */}
//                 {booster.text && !booster.cost ? (
//                   <p>{booster.text}</p>
//                 ) : (
//                   <>
//                     <p>${booster.cost} | {booster.to_limit || booster.to_level || booster.to_speed}</p>
//                     {/* Show a message if the user can't afford the booster */}
//                     {!canAfford && (
//                       <p className="insufficient-balance">
//                         Insufficient balance: You need ${booster.cost - userBalance} more.
//                       </p>
//                     )}
//                   </>
//                 )}
//               </div>
              
//               {/* Show arrow if the booster has a cost and the user can afford it */}
//               {booster.cost && canAfford && (
//                 <div className="booster-item-arrow" onClick={() => openModal(booster)}>→</div>
//               )}
//             </div>
//           );
//         })}
//       </div>

//       {/* Booster Modal */}
//       {selectedBooster && (
//         <BoosterModal 
//           show={showModal} 
//           onClose={closeModal} 
//           booster={selectedBooster} 
//           errorMessage={userBalance < selectedBooster?.cost ? "Insufficient balance to purchase this booster" : null} 
//         />
//       )}
//     </div>
//   );
// };

// export default BoostersList;


import React, { useState, useContext } from 'react';
import BoosterModal from './BoosterModal';
import '../css/BoostersList.css'; // Custom CSS for Boosters List
import { AppContext } from '../context/AppContext';

const BoostersList = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedBooster, setSelectedBooster] = useState(null);
  const { state } = useContext(AppContext);

  const userBalance = state?.userData?.user?.balance || 0;
  const boosters = state?.userBoosters?.other_boosters;

  if (!boosters || Object.keys(boosters).length === 0) {
    return <div>No other boosters available.</div>;
  }

  const openModal = (booster) => {
    setSelectedBooster(booster);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedBooster(null);
  };

  return (
    <div className="boosters-list">
      <h3>Other Boosters:</h3>
      <div className="boosters-container">
        {Object.values(boosters).map((booster) => {
          const canAfford = booster.cost && userBalance >= booster.cost;

          return (
            <div key={booster.title} className="booster-item d-flex align-items-center">
              
              {booster.icon && (
                <img src={booster.icon} alt={booster.title} className="booster-item-icon" />
              )}

              <div className="booster-item-details flex-grow-1">
                <h4>{booster.title}</h4>

                {booster.text && !booster.cost ? (
                  <p>{booster.text}</p>
                ) : (
                  <p>${booster.cost} | {booster.to_limit || booster.to_level || booster.to_speed}</p>
                )}
              </div>
              
              {booster.cost && (
                <div 
                  className={`booster-item-arrow ${canAfford ? '' : 'disabled'}`} 
                  onClick={() => openModal(booster)}
                >
                  →
                </div>
              )}
            </div>
          );
        })}
      </div>

      {selectedBooster && (
        <BoosterModal 
          show={showModal} 
          onClose={closeModal} 
          booster={selectedBooster} 
          errorMessage={
            userBalance < selectedBooster?.cost 
              ? `Insufficient balance: You need $${selectedBooster.cost - userBalance} more.` 
              : null
          } 
        />
      )}
    </div>
  );
};

export default BoostersList;
