// src/components/ReferralCount.js
import React from 'react';
import '../css/ReferralCount.css'; // Custom CSS for ReferralCount

const ReferralCount = ({ count }) => {
  return (
    <div className="referral-count text-center">
      <h1> Referrals</h1>
      <p className="bonus">+{count}</p>
    </div>
  );
};

export default ReferralCount;
