import React, { useContext, useEffect, useState } from 'react';
import '../css/TaskList.css'; // Custom CSS for TaskList
import { AppContext } from '../context/AppContext';
import axios from 'axios';
import config from '../config';
import { ThreeDots } from 'react-loader-spinner';

const TaskList = ({ tasks, currentTab }) => {
  const { dispatch } = useContext(AppContext);
  const { state } = useContext(AppContext);
  const username = state.userData.user.username; // Corrected access to username
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState({}); // Keep track of timers for each task

  // Function to open the task link in a new tab
  const openLink = (link, taskId, reward) => {
    // Start the timer for the task
    if (!timer[taskId]) {
      setTimer((prev) => ({ ...prev, [taskId]: 600 })); // Start a 10-minute timer (600 seconds)
    }
    window.open(link, '_blank'); // Open the task link in a new tab
    // send post with task id and username to backend
    const apiUrl = `${config.apiUrl}/update-special-task-status`; // Backend API endpoint to update the special task

    axios.post(apiUrl, { username, taskId, reward })
      .then((response) => {
        console.log('Task status updated:', response.data);
        // Add reward to the user's balance
        dispatch({ type: 'UPDATE_BALANCE', payload: reward });

        // You can also update the local state here if needed
      })
      .catch((error) => {
        console.error('Error updating task status:', error);
    });
   
  };

  // Timer logic
  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prev) => {
        const newTimers = { ...prev };
        Object.keys(newTimers).forEach((taskId) => {
          if (newTimers[taskId] > 0) {
            newTimers[taskId] -= 1; // Decrease time by 1 second
          }
        });
        return newTimers;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);


  const claimcoins = async (type, id) => {
    try {
      const apiUrl = `${config.apiUrl}/claim-coins`;
      setLoading(true);
      console.log(username, type, id)
      const response = await axios.post(apiUrl, { username, type, id });
      console.log(response);
      setLoading(false);
      // reload the page
      window.location.reload();
    } catch (error) {
      console.error('Failed to claim coins:', error);
      setLoading(false);
    }
  };




  if (loading) {
    return (
      <div className="loader-container">
        <ThreeDots color="#00BFFF" height={80} width={80} />
      </div>
    );
  }

  const renderTaskItem = (task) => {
    switch (currentTab) {
      case 'Special':
        return (
          <div key={task.id} className="task-item">
            <div className="task-icon">
              <img src={task.icon} alt={task.title} />
            </div>
            <div className="task-details">
              <h4>{task.title}</h4>
              <p>{task.reward}</p>
            </div>

            {/* Check if task is completed, and show 'Completed' message or icon */}
            {task.status === 'completed' ? (
              <div className="completed-status">
                <span>✅ Completed</span> {/* You can replace this with an icon if needed */}
              </div>
            ) : (
              <>
                {/* If timer is active for this task, show the countdown. Else, show the link button */}
                {timer[task.id] > 0 ? (
                  <div className="timer">
                    {/* Convert the timer in seconds to mm:ss format */}
                    <span>{Math.floor(timer[task.id] / 60)}:{('0' + (timer[task.id] % 60)).slice(-2)}</span>
                  </div>
                ) : (
                  <button className="claim-button" onClick={() => openLink(task.link, task.id, task.reward)}>
                    →
                  </button>
                )}
              </>
            )}
          </div>
        );

      case 'Leagues':
        return (
          <div key={task.id} className="task-item">
            <div className="task-icon">
              <img src={task.icon} alt={task.title} />
            </div>
            <div className="task-details">
              <h4>{task.title}</h4>
              <p>{task.reward}</p>
              <div className="task-progress-bar">
                <div className="task-progress" style={{ width: `${task.progress}%` }}></div>
              </div>
            </div>
            {task.status === 'active' ? (
              <button className="claim-button" onClick={() => claimcoins("Leagues", task.id)}>Claim</button>
            ) : task.status === 'claimed' ? (
              <span className="claimed-text">Claimed</span>
            ) : null}          </div>
        );
      case 'RefTasks':
        return (
          <div key={task.id} className="task-item">
            <div className="task-icon">
              <img src={task.icon} alt={task.title} />
            </div>
            <div className="task-details">
              <h4>{task.title}</h4>
              <p>{task.reward}</p>
              <div className="task-progress-bar">
                <div className="task-progress" style={{ width: `${task.progress}%` }}></div>
              </div>
            </div>
            {task.status === 'active' ? (
              <button className="claim-button" onClick={() => claimcoins("RefTasks", task.id)}>Claim</button>
            ) : task.status === 'claimed' ? (
              <span className="claimed-text">Claimed</span>
            ) : null}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="task-list">
      {tasks.map(renderTaskItem)}
    </div>
  );
};

export default TaskList;
