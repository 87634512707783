import React from 'react';
import CoinDisplay from './CoinDisplay';
import LargeCoin from './LargeCoin';
import ProgressBar from './ProgressBar';
import IconBar from './IconBar';
import '../css/RefPage.css'; // Custom CSS for RefPage


const TapPage = () => {
  // const [referralCode, setReferralCode] = useState(null);
  // const [authenticated, setAuthenticated] = useState(false);
  // const [loading, setLoading] = useState(true);
  // const [referralData, setReferralData] = useState(null);

 

 

  // if (loading) {
  //   return (
  //     <div className="loader-container">
  //       <ThreeDots color="#00BFFF" height={80} width={80} />
  //     </div>
  //   );
  // }

  // if (!authenticated) {
  //   return <div>Authentication failed. Please try again later.</div>;
  // }

  return (
    <div className="ref-page container">
      <CoinDisplay />
      <LargeCoin />
      <ProgressBar />
      <IconBar/>
    </div>
  );
};

export default TapPage;
