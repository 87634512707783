import React, { useEffect, useState, useContext } from 'react';
import CoinDisplay from './CoinDisplay';
import DailyBoosters from './DailyBoosters';
import BoostersList from './BoostersList';
import IconBar from './IconBar';
import '../css/BoostPage.css'; // Custom CSS for Boost Page
import axios from 'axios';
import config from '../config';
import { AppContext } from '../context/AppContext';
import { ThreeDots } from 'react-loader-spinner';

const BoostPage = () => {
  const { state, dispatch } = useContext(AppContext); // Global App context
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBoosters = async () => {
      const apiUrl = `${config.apiUrl}/boosters`;
      const username = state.userData?.user?.username;

      if (!username) return; // Avoid making API call if username is missing

      try {
        const response = await axios.post(apiUrl, { username });
        dispatch({ type: 'SET_USER_BOOSTERS', payload: response.data.boosters });
      } catch (error) {
        console.error('Failed to fetch boosters:', error);
      } finally {
        setLoading(false); // Ensure loading state is false after request
      }
    };

    fetchBoosters(); // Run fetch on initial load only

  }, []); // Empty dependency array ensures this only runs on mount

  if (loading) {
    return (
      <div className="loader-container">
        <ThreeDots color="#00BFFF" height={80} width={80} />
      </div>
    );
  }

  return (
    <div className="boost-page container">
      <CoinDisplay />
      <DailyBoosters />
      <BoostersList />
      <IconBar />
    </div>
  );
};

export default BoostPage;
